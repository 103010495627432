import React from 'react'
import calendarIcon from "../../../assets/Avatar.png";
import notificationicon from "../../../assets/notificationAvatar.png";

const UnreadNotification = () => {
  return (
    <div>
        <div className="w-full border-b border-gray-400 border-t p-2 flex flex-wrap bg-gray-100">
                    <img
                      className="ml-3 lg:w-10 lg:h-10 mt-2 mr-3 sm:w-9 sm:h-9 rounded-full"
                      src={calendarIcon}
                      alt="Jese"
                    />
                    <div className="text-[13px] mt-1 break-all h-auto w-[240px] mr-8 break-words">
                     The software will go under update during 12:30 to 1:30 on March 07,2024
                      <p className="text-[8px]">March 1,2024</p>
                    </div>
                    <span className="mt-3">X</span>
                  </div>
                  <div className="w-full border-b border-gray-400 p-2 flex flex-wrap bg-gray-100">
                    <img
                      className="ml-3 lg:w-10 lg:h-10 mt-2 mr-3 sm:w-9 sm:h-9 rounded-full"
                      src={calendarIcon}
                      alt="Jese"
                    />
                    <div className="text-[13px] mt-1 break-all h-auto w-[240px] mr-8 break-words">
                     The software will go under update during 12:30 to 1:30 on March 07,2024
                      <div className="text-[8px]">March 1,2024</div>
                    </div>
                    <span className="mt-3">X</span>
                  </div>
                  <div className="w-full border-b border-gray-400 p-2 flex flex-wrap bg-gray-100">
                    <img
                      className="ml-3 lg:w-10 lg:h-10 mt-2 mr-3 sm:w-9 sm:h-9 rounded-full"
                      src={notificationicon}
                      alt="Jese"
                    />
                    <div className="text-[13px] mt-1 break-all h-auto w-[240px] mr-8 break-words">
                     The software will go under update during 12:30 to 1:30 on March 07,2024
                      <div className="text-[8px]">March 1,2024</div>
                    </div>
                    <span className="mt-3">X</span>
                  </div>
    </div>
  )
}

export default UnreadNotification