import React, { useState } from "react";
import TotalClient from "./totalClient";
import TableLeaderBoard from "./tableLeaderBoard";
import UpcomingAgenda from "./upcomingAgenda";
import SideBarMain from "../../../widgets/sideBarMain";

const DashBoard = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="flex h-screen bg-white">
        <div className="hidden md:block">
          <SideBarMain />
        </div>
        {open && <SideBarMain />}
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div className="md:hidden flex items-center justify-between h-16 bg-white border-b border-gray-200">
            <div className="flex items-center py-5 px-4">
              <div
                onClick={() => {
                  setOpen(!open);
                }}
                className="text-gray-500 focus:outline-none focus:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col flex-1 overflow-y-auto ">
              {/* two component */}
              <TotalClient />
            </div>
          </div>
          <div className="p-4 ps-2">
            <TableLeaderBoard />
            <UpcomingAgenda />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
