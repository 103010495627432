import React, { useState } from "react";
import PaymentModal from "./paymentModal";

const ContractModal = ({ onClose }) => {
  const [paymentModal, setPaymentModal] = useState(false);
  const handleOnClose = (e) => {
    if (e.target.id === "container") {
      onClose();
    }
  };
  const handleOnPayment = () => {
    setPaymentModal(false);
  };
  return (
    <>
      <div
        onClick={handleOnClose}
        id="container"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="bg-white p-2 w-[620px] rounded-xl">
          <div className="relative px-4  max-w-md max-h-full">
            <div className="relative bg-white  ">
              <div className="flex items-center justify-between xl:pt-5 p-4 md:p-5  rounded-t ">
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-700">
                  Contract
                </h3>
              </div>
              <div className="p-4 xl:pt-1  ">
                <form className="space-y-4">
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                    >
                      Confirm Client Name
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[532px] p-2.5 "
                      placeholder="Dr. A. Shukla"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Confirm Client Name
                    </label>
                    <div className="grid grid-cols-2">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-gray-50 border placeholder-blue-500 placeholder:font-semibold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[330px] p-2.5 px-3"
                        placeholder="Doc-aid onboarding Contract"
                        disabled
                      />
                      <div className=" bg-blue-800 border ml-40 text-white border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-800  w-[185px] p-2.5 pl-3 placeholder:font-semibold ">
                        Download Contract
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="inline ml-2 mb-1 "
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </div>
                    </div>{" "}
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      setPaymentModal(!paymentModal);
                    }}
                    className="px-4 text-white bg-gray-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {paymentModal && <PaymentModal onClose={handleOnPayment} />}
    </>
  );
};

export default ContractModal;
