import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const steptwvalidation = Yup.object().shape({
  BankName: Yup.string().min(2).max(25).required("Please enter bank name"),
  AccountHolderName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter account holder name"),
  AccountNo: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your account no."),
  IFSCCode: Yup.string().required("Please enter bank ifsc code"),
  InHandSalary: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your In Hand Salary"),
  BranchName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter bank branch name"),
  Incentive: Yup.string().min(2).max(25).required("Please enter Incentive"),
  //  CancelledCheque:Yup.string().required("Please attach cancelled cheque"),
});

const BankDetails = ({ next, prev, data }) => {
  const handleSubmit = (values) => {
    next(values);
  };

  return (
    <>
      <Formik
        validationSchema={steptwvalidation}
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className="w-full md:w-[90%] my-3 mx-2 ">
            <div className="flex flex-wrap px-3 mb-6">
              <div className="w-full md:w-1/2 px-3 "></div>
            </div>

            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="name"
                  className="block  mx-2  uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  Bank Name
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="name"
                  autoComplete="off"
                  name="BankName"
                  id="BankName"
                  placeholder="Bank Name"
                />
                <ErrorMessage
                  name="BankName"
                  component="div"
                  className="text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="name"
                  className="mx-2  block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Account Holder Name
                </label>
                <Field
                  className="appearance-none block  md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="name"
                  autoComplete="off"
                  name="AccountHolderName"
                  id="AccountHolderName"
                  placeholder="Account Holder Name"
                />
                <ErrorMessage
                  name="AccountHolderName"
                  component="div"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="name"
                  className="mx-2 block uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  Account no.
                </label>
                <Field
                  className="md:w-[280px] w-[350px] -none block  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  autoComplete="off"
                  name="AccountNo"
                  id="AccountNo"
                  placeholder="Account no."
                />
                <ErrorMessage
                  name="AccountNo"
                  component="div"
                  className="text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="name"
                  className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  IFSC Code
                </label>
                <Field
                  className="md:w-[280px] w-[350px] appearance-none block  bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  autoComplete="off"
                  name="IFSCCode"
                  id="IFSCCode"
                  placeholder="IFSC Code"
                />
                <ErrorMessage
                  name="IFSCCode"
                  component="div"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 px-3 ">
              <div className="flex flex-wrap md:w-1/2 -mx-3  w-full px-3">
                <label
                  htmlFor="email"
                  className="mx-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  In-Hand Salary (per month)
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded  py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  autoComplete="off"
                  name="InHandSalary"
                  id="InHandSalary"
                  placeholder="In-Hand Salary"
                />
                <ErrorMessage
                  name="InHandSalary"
                  component="div"
                  className="text-red-600 text-xs italic mb-2"
                ></ErrorMessage>
              </div>
              <div className=" mx-3 md:w-1/2 w-full px-3">
                <label
                  htmlFor="text"
                  className="float-left px-6 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Branch Name
                </label>
                <Field
                  className="mx-3 appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  autoComplete="off"
                  name="BranchName"
                  id="BranchName"
                  placeholder="Branch Name"
                />
                <ErrorMessage
                  name="BranchName"
                  component="div"
                  className="text-red-600 text-left mx-3 text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="w-[500px] md:w-1/2 px-3  md:mb-0">
                <label
                  htmlFor="name"
                  className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Incentive
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px]  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="name"
                  autoComplete="off"
                  name="Incentive"
                  id="Incentive"
                  placeholder="Incentive"
                />
                <ErrorMessage
                  name="Incentive"
                  component="div"
                  className="text-red-500 text-left text-xs  mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="image"
                  className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Cancelled Cheque
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="file"
                  autoComplete="off"
                  name="CancelledCheque"
                  id="CancelledCheque"
                  placeholder="Uplaod Image"
                />
                <ErrorMessage
                  name="CancelledCheque"
                  component="div"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>

            <div className="my-2 flex justify-between items-center">
              <button
                type="button"
                className="bg-gray-600 text-white hover:bg-gray-600 hover:text-gray-300 px-4 py-2 rounded-xl"
                onClick={() => prev(values)}
              >
                Prev
              </button>
              <button
                type="submit"
                className="bg-gray-600 text-white hover:bg-gray-600 hover:text-gray-300 mx-3 px-4 py-2 -my-2 float-right rounded-xl"
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BankDetails;
