import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import reshitaLogo from "../../assets/image 11.png";
import background from "../../assets/background.png";
import apiServices from "../../service/apiService";
import { useNavigate } from "react-router-dom";
import Spinner from "../page/loading/Spinner";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const validateFormik = useFormik({
    initialValues: {
      email: "",
      newPassword: "",
      confirmPassword: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("please enter email"),
      newPassword: Yup.string()
        .min(7, "Password must be atleast 7 characters long")
        .max(20, "Password cannot more than 20 charcter long ")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("please enter the password")
        .test("Password cannot contain your name ", function (value) {
          const nameFromEmail = this.parent.email.split("@")[0].toLowerCase();
          return !value.toLowerCase().includes(nameFromEmail);
        }),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match") // Reference 'password' field
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      handleForgetPassword(values);
    },
  });
  const handleForgetPassword = async (values) => {
    setLoading(true);
    try {
      const response = await apiServices.put(
        "authentication",
        "/home/forgotPassword",
        {
          "Content-Type": "application/json",
        },
        null,
        {
          email: values.email,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        }
      );
      if (response) {
        //response.statuscode===200
        navigate("/dashBoard");
      }
    } catch (error) {
      console.error("error coming in forgetPassword", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="h-screen bg-white text-gray-900 flex flex-col items-center justify-between">
        <div className="max-w-screen-xl m-0 sm:m-10 shadow sm:rounded-lg flex justify-center flex-1 ">
          <div className="lg:flex-1 bg-white text-center flex flex-col lg:mb-20 lg:justify-center items-center">
            {/* Doc-Aid Logo */}
            <div className="w-full flex justify-center items-center">
              <div className="w-full mt-20">
                <img
                  alt="img"
                  className="w-full lg:w-[95%] justify-center"
                  src={reshitaLogo}
                />
              </div>
            </div>
            {/* for mobile screen User Interface */}
            <div
              className="absolute bottom-0  lg:hidden w-full "
              style={{
                backgroundImage: `url(${background})`,
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
              }}
            >
              <div className="lg:w-1/2 xl:w-1/2 p-6 sm:p-16 flex justify-center items-center">
                {/* input box */}
                <div
                  style={{
                    backgroundColor: "rgb(188, 190, 176)",
                    backgroundImage: `url(${background})`,
                    backgroundSize: "cover",
                  }}
                  className="bg-blend-overlay box-border inline-block flex-wrap w-[480px] max-h-full max-w-full justify-center align-center  content-align mb-20 px-6 mt-20 py-4 rounded-lg "
                >
                  <div className="w-full mt-4rem">
                    <div className="my-1 border-b text-center">
                      <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"></div>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validateFormik.handleSubmit();
                      }}
                    >
                      <div className="mx-auto max-w-xs">
                        <input
                          className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                          type="text"
                          name="email"
                          value={validateFormik.values.email}
                          onChange={validateFormik.handleChange}
                          placeholder="Email"
                        />
                        {validateFormik.touched.email &&
                        validateFormik.errors.email ? (
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.email}
                          </p>
                        ) : null}
                        <input
                          className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                          type="text"
                          name="newPassword"
                          value={validateFormik.values.newPassword}
                          onChange={validateFormik.handleChange}
                          placeholder="Password"
                        />
                        {validateFormik.touched.newPassword &&
                        validateFormik.errors.newPassword ? (
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.newPassword}
                          </p>
                        ) : null}
                        <input
                          className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                          type="text"
                          name="confirmPassword"
                          value={validateFormik.values.confirmPassword}
                          onChange={validateFormik.handleChange}
                          placeholder="Confirm Password"
                        />
                        {validateFormik.touched.confirmPassword &&
                        validateFormik.errors.confirmPassword ? (
                          <p style={{ color: "red" }}>
                            {validateFormik.errors.confirmPassword}
                          </p>
                        ) : null}
                        <div className="flex justify-between">
                          <label className="block text-gray-500 font-bold my-4" />
                        </div>
                        <button className="bg-blue-800 mb-5 tracking-wide font-semibol text-white w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                          <span className="ml-">Forget Password</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hidden lg:block lg:w-1/2 xl:w-6/12 p-6 sm:p-12"
            style={{
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
            }}
          >
            {/* input box */}
            <div
              style={{
                backgroundColor: "rgb(188, 190, 176)",
                backgroundImage: `url(${background})`,
                backgroundSize: "cover",
              }}
              className="bg-blend-overlay box-border inline-block flex-wrap w-[480px] lg:w-[480px] max-h-full max-w-full justify-center align-center  content-align  px-6 mt-8 rounded-lg "
            >
              <div className="mt-12 flex flex-col items-center">
                <div className="w-full flex-1 mt-8">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validateFormik.handleSubmit();
                    }}
                  >
                    <div className="mx-auto max-w-xs">
                      <input
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                        type="text"
                        name="email"
                        value={validateFormik.values.email}
                        onChange={validateFormik.handleChange}
                        placeholder="Email"
                      />
                      {validateFormik.touched.email &&
                      validateFormik.errors.email ? (
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.email}
                        </p>
                      ) : null}
                      <input
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                        type="text"
                        name="newPassword"
                        value={validateFormik.values.newPassword}
                        onChange={validateFormik.handleChange}
                        placeholder="Password"
                      />
                      {validateFormik.touched.newPassword &&
                      validateFormik.errors.newPassword ? (
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.newPassword}
                        </p>
                      ) : null}
                      <input
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                        type="text"
                        name="confirmPassword"
                        value={validateFormik.values.confirmPassword}
                        onChange={validateFormik.handleChange}
                        placeholder="Confirm Password"
                      />
                      {validateFormik.touched.confirmPassword &&
                      validateFormik.errors.confirmPassword ? (
                        <p style={{ color: "red" }}>
                          {validateFormik.errors.confirmPassword}
                        </p>
                      ) : null}
                      <div className="flex justify-between">
                        <label className="block text-gray-500 font-bold my-4" />
                      </div>
                      <button className="bg-blue-800 mb-5 tracking-wide font-semibol text-white w-full py-4 rounded-lg  transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                        <span className="ml-">Forget Password</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};
export default ForgetPassword;
