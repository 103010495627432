import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";


const PersonalDetail = ({ next, prev, data }) => {
  const steptwovalidation = Yup.object().shape({
    // adhar: Yup.string().required("Please attach your adhar"),
    //  PanCard: Yup.string().required("Please attach your Pan card"),
    // Marksheet: Yup.string().required("Please attach your Marksheet"),
    qualification: Yup.string().required("Please select your qualification"),
    Specialization: Yup.string().required("Please enter your Specialization"),
    College: Yup.string().min(2).max(25).required("Please enter your College"),
    Year: Yup.string()
      .required("please enter Year")
      .min(4, "Invalid year")
      .max(4, "Invalid Year"),
  });
  
  const handleSubmit = (values) => {
    next(values);
  };

  return (
    <>
      <Formik
        validationSchema={steptwovalidation}
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form className="w-full md:w-[90%]  my-3 mx-2 ">
            <div className="flex flex-wrap px-3 mb-6">
              <div className="w-full md:w-1/2 px-3 "></div>
            </div>

            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                <label
                  htmlFor="name"
                  className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Highest Qualification
                </label>
                <div className="relative">
                  <Field
                    name="qualification"
                    as="select"
                    id="department"
                    className="md:w-[280px] w-[350px] block appearance-none mb-2  bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-32 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option>Qualificaton</option>
                    <option>New Mexico</option>
                    <option>Missouri</option>
                    <option>Texas</option>
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 -right-2 flex items-center px-10 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <ErrorMessage
                  name="qualification"
                  component="div"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-6 -mx-3  md:mb-0">
                <label
                  htmlFor="name"
                  className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Specialization
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="name"
                  autoComplete="off"
                  name="Specialization"
                  id="designation"
                  placeholder="Specialization"
                />
                <ErrorMessage
                  name="Specialization"
                  component="div"
                  className="text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="name"
                  className="mx-2  block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  College
                </label>
                <Field
                  className="appearance-none block  md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="lastname"
                  autoComplete="off"
                  name="College"
                  id="lastname"
                  placeholder="College"
                />

                <ErrorMessage
                  name="College"
                  component="div"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="name"
                  className="block  mx-2  uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  Year
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="number"
                  autoComplete="off"
                  name="Year"
                  id="name"
                  placeholder="Year"
                />

                <ErrorMessage
                  name="Year"
                  component="div"
                  className="text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="image"
                  className="mx-2 block uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  Adhar Card
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="file"
                  autoComplete="off"
                  name="adhar"
                  id="adhar"
                  placeholder="Upload Iamge"
                />
                <ErrorMessage
                  name="adhar"
                  component="div"
                  className="text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="image"
                  className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Pan Card
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="file"
                  autoComplete="off"
                  name="PanCard"
                  id="adhar"
                  placeholder="Uplaod Image"
                />
                <ErrorMessage
                  name="PanCard"
                  component="div"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="image"
                  className="mx-2 block uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  Latest Qualification Marksheet
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="file"
                  autoComplete="off"
                  name="Marksheet"
                  id="name"
                  placeholder="Upload Iamge"
                />
                <ErrorMessage
                  name="Marksheet"
                  component="div"
                  className="text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="image"
                  className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Experience letter
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="file"
                  autoComplete="off"
                  name="Experience"
                  id="lastname"
                  placeholder="Uplaod Image"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="image"
                  className="mx-2 block uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  Relieving Letter
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="file"
                  autoComplete="off"
                  name="Relieving"
                  id="name"
                  placeholder="Upload Iamge"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="image"
                  className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Pay Slip (Last Three months)
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="file"
                  autoComplete="off"
                  name="Slip"
                  id="Slip"
                  placeholder="Uplaod Image"
                />
              </div>
            </div>

            <div className="my-2 flex justify-between items-center">
              <button
                type="button"
                className="bg-gray-600 text-white hover:bg-gray-600 hover:text-gray-300 px-4 py-2 rounded-xl"
                onClick={() => prev(values)}
              >
                Prev
              </button>
              <button
                type="submit"
                className="bg-gray-600 text-white hover:bg-gray-600 hover:text-gray-300 mx-3 px-4 py-2 -my-2 float-right rounded-xl"
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalDetail;
