import React from "react";

const UpcomingAgenda = () => {
  return (
    <>
      <table className=" ml-3 xl:pl-3 sm:pr-4 xl:w-[300px] md:w-6/20  divide-gray-200 inline-block  justify-center align-center border-2 border-grey-700 content-align  mx-auto rounded-lg">
        <thead>
          <tr>
            <th className="py-1 mb-10 pt-4 my-4 text-center md:text-[16px] xl:text-[20px] pb-4">
              Upcoming Agendas
            </th>
          </tr>
        </thead>
        <tbody className="md:mx-2 xl:text-[16px] lg:mx-4 md:text-[14px] lg:text-[15px] mb-4">
          <tr className="ps-3">
            <td>
              <div className="ml-3 px-2 mb-1 xl:text-[16px] text-orange-800 bg-orange-200 text-base font-semibold divide-gray-200 inline-block  justify-center align-center border-2 border-grey-700 content-align  mx-auto rounded-lg">
                11.00-12.00 feb22,2024
              </div>
              <div className="ml-3 font-semibold text-black mb-5">
                Meeting with Dr. Gupta
              </div>
            </td>
          </tr>

          <tr className="ps-3">
            <td>
              <div className="ml-3 px-2 mb-1 xl:text-[16px] text-blue-800 bg-blue-200 text-base font-semibold divide-gray-200 inline-block  justify-center align-center border-2 border-grey-700 content-align  mx-auto rounded-lg">
                11.00-12.00 feb22,2024
              </div>
              <div className="ml-3 font-semibold text-black mb-5">
                Meeting with Dr. Gupta
              </div>
            </td>
          </tr>

          <tr className="ps-3">
            <td>
              <div className="ml-3 px-2 mb-1 xl:text-[16px] text-violet-800 bg-violet-200 text-base font-semibold divide-gray-200 inline-block  justify-center align-center border-2 border-grey-700 content-align  mx-auto rounded-lg">
                11.00-12.00 feb22,2024
              </div>
              <div className="ml-3 font-semibold text-black mb-5">
                Meeting with Dr. Gupta
              </div>
            </td>
          </tr>
          <tr className="ps-3">
            <td>
              <div className="ml-3 xl:text-[16px] mb-1 text-red-800 bg-red-200 text-base font-semibold divide-gray-200 inline-block  justify-center align-center border-2 border-grey-700 content-align  mx-auto rounded-lg">
                11.00-12.00 feb22,2024
              </div>
              <div className="ml-3 font-semibold text-black mb-5">
                Meeting with Dr. Gupta
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default UpcomingAgenda;
