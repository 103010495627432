import React, { useState } from "react";
import SideBarMain from "../../../widgets/sideBarMain";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";

const registrationValidation = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  lastname: Yup.string().min(2).max(25).required("Please enter your name"),
  Specialization: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your Specialization"),
  email_id: Yup.string().email().required("Please enter your email"),
  address: Yup.string().min(2).max(25).required("Please enter your address"),
  phone_no: Yup.string()
    .required("enter phone number")
    .min(10, "enter valid phone number")
    .max(10, "enter valid phone number"),
  state: Yup.string().min(2).max(25).required("Please enter your state"),
  city: Yup.string().min(2).max(25).required("Please enter your city"),
  Compounder_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter compounder name"),
  compounder_number: Yup.string()
    .required("enter phone number")
    .min(10, "enter valid phone number")
    .max(10, "enter valid phone number"),
  // clinic_days:Yup.string().min(2).max(25).required("Please select available day"),
  // clinic_timings: Yup.string()
  //   .min(2)
  //   .max(25)
  //   .required("Please select your timing"),
});
const ClientRegistration = () => {
  const [open, setOpen] = useState(false);
  const handleSubmit = (values) => {
    console.log(values);
  };
  const [selectedDays, setSelectedDays] = useState([]);

  const handleDayClick = (index) => {
    // Check if the day is already selected
    const isDaySelected = selectedDays.includes(index);

    // Update the selected days array
    if (isDaySelected) {
      setSelectedDays(selectedDays.filter((day) => day !== index));
    } else {
      setSelectedDays([...selectedDays, index]);
    }
  };
  return (
    <>
      <div className="flex h-screen bg-white">
        <div className="hidden md:block">
          <SideBarMain />
        </div>

        {open && <SideBarMain />}
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div className="md:hidden flex items-center justify-between h-16 bg-white border-b border-gray-200">
            <div className="flex items-center py-5 px-4">
              <div
                onClick={() => {
                  setOpen(!open);
                }}
                className="text-gray-500 focus:outline-none focus:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="ml-10 py-3 text-white px-6 font-bold mt-10 w-[90%] border-b bg-gradient-to-r from-blue-300 to-green-300">
              Client Registration
            </div>
          </div>
          <div className="p-2 ">
            <div className="lg:ml-10 sm:ml-2 mt-4 ">
              <Formik
                validationSchema={registrationValidation}
                initialValues={{
                  name: "",
                  lastname: "",
                  Specialization: "",
                  email_id: "",
                  address: "",
                  phone_no: "",
                  state: "",
                  city: "",
                  compounder_number: "",
                  Compounder_name: "",
                  clinic_days: "",
                  clinic_timings: "",
                }}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form className="w-[80%] ml-10 my-3 ">
                    <div className="flex flex-wrap  ">
                      <div className="w-full md:w-1/2 md:mb-0">
                        <label
                          htmlFor="name"
                          className=" block  mx-2  uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                        >
                          First Name
                        </label>
                        <Field
                          className=" appearance-none block xl:w-[400px] lg:w-[300px]  sm:w-[230px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                          type="name"
                          autoComplete="off"
                          name="name"
                          id="name"
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className=" text-red-500 text-left text-xs mb-2 italic"
                        />
                      </div>
                      <div className="w-full md:w-1/2 sm:px-8 lg:px-3">
                        <label
                          htmlFor="name"
                          className="mx-2  block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Last Name
                        </label>
                        <Field
                          className="appearance-none block xl:w-[400px] lg:w-[300px]   w-[230px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="lastname"
                          autoComplete="off"
                          name="lastname"
                          id="lastname"
                          placeholder="Last Name"
                        />

                        <ErrorMessage
                          component="div"
                          name="lastname"
                          className="text-red-500 text-left text-xs italic mb-2"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 ">
                      <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                        <label
                          htmlFor="name"
                          className=" mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Specialization
                        </label>
                        <Field
                          className="xl:w-[400px] lg:w-[300px]  sm:w-[230px] none block  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                          autoComplete="off"
                          name="Specialization"
                          id="Specialization"
                          placeholder="Pediatrics"
                        />

                        <ErrorMessage
                          component="div"
                          name="Specialization"
                          className="text-red-500 text-left text-xs italic mb-2"
                        ></ErrorMessage>
                      </div>
                      <div className="w-full md:w-1/2 lg:px-6 sm:px-11 -mx-3  md:mb-0">
                        <label
                          htmlFor="name"
                          className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Email id
                        </label>
                        <Field
                          className="appearance-none block xl:w-[400px] lg:w-[300px]  sm:w-[230px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                          type="email"
                          autoComplete="off"
                          name="email_id"
                          id="email_id"
                          placeholder="a.shukla10@gmail.com"
                        />

                        <ErrorMessage
                          component="div"
                          name="email_id"
                          className="text-red-500 text-left text-xs mb-2 italic"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 ">
                      <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <label
                          htmlFor="name"
                          className=" mx-2 block uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                        >
                          Address
                        </label>
                        <Field
                          className="xl:w-[400px] lg:w-[300px]  sm:w-[230px] none block  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                          type="name"
                          autoComplete="off"
                          name="address"
                          id="address"
                          placeholder="128, shashi arcade, gandhi maidan"
                        />

                        <ErrorMessage
                          component="div"
                          name="address"
                          className=" text-red-500 text-left text-xs mb-2 italic"
                        ></ErrorMessage>
                      </div>
                      <div className="w-full md:w-1/2 lg:px-3 sm:px-8">
                        <label
                          htmlFor="number"
                          className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Phone No.
                        </label>
                        <Field
                          className="xl:w-[400px] lg:w-[300px]  sm:w-[230px] appearance-none block  bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          autoComplete="off"
                          name="phone_no"
                          id="phone_no"
                          placeholder="9873478112"
                        />

                        <ErrorMessage
                          component="div"
                          name="phone_no"
                          className="text-red-500 text-left text-xs italic mb-2"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 ">
                      <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <label
                          htmlFor="name"
                          className=" mx-2 block uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                        >
                          State
                        </label>
                        <Field
                          className="xl:w-[400px] lg:w-[300px]  sm:w-[230px] none block  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                          type="name"
                          autoComplete="off"
                          name="state"
                          id="state"
                          placeholder="Bihar"
                        />

                        <ErrorMessage
                          component="div"
                          name="state"
                          className=" text-red-500 text-left text-xs mb-2 italic"
                        ></ErrorMessage>
                      </div>
                      <div className="w-full md:w-1/2 lg:px-3 sm:px-8">
                        <label
                          htmlFor="text"
                          className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          City
                        </label>
                        <Field
                          className="xl:w-[400px] lg:w-[300px]  sm:w-[230px] appearance-none block  bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          autoComplete="off"
                          name="city"
                          id="city"
                          placeholder="Patna"
                        />

                        <ErrorMessage
                          component="div"
                          name="city"
                          className="text-red-500 text-left text-xs italic mb-2"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3">
                      <div className=" w-[500px] md:w-1/2 px-3  md:mb-0">
                        <label
                          htmlFor="name"
                          className="mx-2  text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Compounder name
                        </label>
                        <Field
                          className=" appearance-none block xl:w-[400px] lg:w-[300px]  sm:w-[230px]  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                          type="name"
                          autoComplete="off"
                          name="Compounder_name"
                          id="Compounder_name"
                          placeholder="Raju"
                        />

                        <ErrorMessage
                          component="div"
                          name="Compounder_name"
                          className="text-red-500 text-left text-xs  mb-2 italic"
                        ></ErrorMessage>
                      </div>
                      <div className="w-full md:w-1/2 lg:px-3 sm:px-8">
                        <label
                          htmlFor="name"
                          className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Compounder number
                        </label>
                        <Field
                          className="appearance-none block xl:w-[400px] lg:w-[300px]  sm:w-[230px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          autoComplete="off"
                          name="compounder_number"
                          id="compounder_number"
                          placeholder="9873478122"
                        />

                        <ErrorMessage
                          component="div"
                          name="compounder_number"
                          className="text-red-500 text-left text-xs italic mb-2"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-[500px]  md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          htmlFor="name"
                          className="mx-2 text-left  block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Clinic Days
                        </label>
                        <div
                          name="clinic_days"
                          className="flex  p-1 rounded-md"
                        >
                          {["S", "M", "T", "W", "T", "F", "S"].map(
                            (day, index) => (
                              <div
                                key={index}
                                className={`px-1 mx-1 flex items-center justify-center w-8 h-8 cursor-pointer ${
                                  selectedDays.includes(index)
                                    ? "border-2 bg-sky-900 text-white"
                                    : "border border-1 border-gray-400"
                                }`}
                                onClick={() => handleDayClick(index)}
                              >
                                {day}
                              </div>
                            )
                          )}
                        </div>
                        <ErrorMessage
                          component="div"
                          name="clinic_days"
                          className="text-red-500  text-left text-xs mb-2 italic"
                        ></ErrorMessage>
                      </div>
                      <div className="w-full md:w-1/2 lg:px-3 sm:px-8">
                        <label
                          htmlFor="name"
                          className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                          Clinic Timings
                        </label>
                        <Field
                          className="appearance-none block xl:w-[400px] lg:w-[300px]  sm:w-[230px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="lastname"
                          autoComplete="off"
                          name="clinic_timings"
                          id="clinic_timings"
                          placeholder="Alternate Contact No."
                        />

                        <ErrorMessage
                          component="div"
                          name="clinic_timings"
                          className="text-red-500 text-left text-xs italic mb-2"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="my-2 flex justify-between float-left">
                      <button
                        className="bg-blue-600 text-white hover:bg-blue-800 hover:text-gray-300  px-8 py-2 -my-2"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientRegistration;
