import React from "react";
import visitLogo from "../../../assets/visitLogo.png";
import clientLogo from "../../../assets/clientLogo.png";

const TotalClient = () => {
  return (
    <>
      <div
        className="items-center justify-between w-full lg:flex lg:w-auto lg:order-1"
        id="mobile-menu-2"
      >
        <ul className="flex mt-10 ml-5 font-medium flex-row  ">
          <li>
            <div className="flex flex-row mr-6 pr-10 border-gray-700 rounded-lg">
              <div
                to="/dashboard"
                className="mr-2 h-14 xl:h-20 xl:px-4 flex items-center rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium   px-2 py-3 me-2 mb-2 dark:hover:text-gray-800    dark:hover:bg-white focus:outline-none dark:focus:ring-blue-800"
                style={{ backgroundColor: "rgb(20,80,159)" }}
              >
                <img
                  src={visitLogo}
                  className="rounded-full "
     
                 alt="Landwind Logo"
                />
              </div>
              <span className="self-center flex flex-col text-20px font-semibold whitespace-nowrap  ">
                {" "}
                Total Visit
                <span>5</span>
              </span>
            </div>
          </li>
          <li>
            <div className="flex  flex-row mr-6 pr-10 border-gray-700 rounded-lg">
              <div
                to="/dashboard"
                className="mr-2 xl:h-20 h-14 xl:px-4  flex items-center rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium   px-2 py-3 me-2 mb-2 dark:hover:text-gray-800    dark:hover:bg-white focus:outline-none dark:focus:ring-blue-800"
                style={{ backgroundColor: "rgb(39,208,149)" }}
              >
                <img
                  src={clientLogo}
                  className="rounded-full h-12"
                  alt="Landwind Logo"
                />
              </div>
              <span className="self-center flex flex-col text-20px font-semibold whitespace-nowrap  ">
                {" "}
                Total Active Clients
                <span>5</span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TotalClient;
