import React, { useState } from "react";
import reshitaLogo from "../assets/reshita.jpg";
import { Link } from "react-router-dom";
import Notification from "../components/page/notification/notification";


const SideBarMain = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [openNotification, setOpenNotification] = useState(false)
  const handleHover = (e) => {
    const icon = e.currentTarget.querySelector('svg');
    if (icon) {
      icon.setAttribute('stroke', 'white'); // Change the stroke color on hover
    }
  };
  const handleOnClose=()=>{
    setOpenNotification(false);
  }
  const handleMouseLeave = (e) => {
    const icon = e.currentTarget.querySelector('svg');
    if (icon) {
      icon.setAttribute('stroke', 'gray'); // Reset the stroke color when mouse leaves
    }
  };
  return (
    <>
      {/* <!-- sidebar --> */}
     
      <div className="flex flex-col w-48 xl:w-60 h-screen bg-red-800">
        <div className="flex items-center  justify-center h-16 bg-gray-100">
          <Link to="/dashBoard" className="mt-3">
            <img
              alt="img"
              className="image mb-3 inline-block h-12"
              src={reshitaLogo}
            />
            <span className="font-bold text-md tracking-wide -ml-6">
              RESHITA
            </span>
          </Link>
        </div>
        <div className="flex flex-col flex-1 overflow-y-auto">
          <nav className="flex-1 px-2 py-4 bg-gray-100">
            <span className="text-sm text-blue-700 pb-4">Overview</span>
            
            <div
              className={` cursor-pointer transition-colors ${
                isHovered ? "text-gray-800" : "text-white"
              }`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Link  onMouseEnter={handleHover} to="/dashBoard"
      onMouseLeave={handleMouseLeave}
               
              className="flex items-center px-4 py-2 mt-2 mb-4 text-gray-800 hover:bg-blue-700 hover:text-white"
            >
                <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2 ml-2 transition-colors duration-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="gray"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
          <path d="M9 22V12h6v10" />
        </svg>
                Home
                
              </Link>{" "}
            </div>

            <Link onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      onClick={()=>{setOpenNotification(!openNotification)}}
              className="flex items-center px-4 py-2 mt-2 mb-4 text-gray-800 hover:bg-blue-700 hover:text-white"
            >
            <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2 ml-2 transition-colors duration-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="gray"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 22s-8-4-8-8V9a6 6 0 1112 0v5c0 4-8 8-8 8zM12 2a2 2 0 012 2h-4a2 2 0 012-2z"
      />
        </svg>
              Notification
            </Link>
            <span className="text-sm text-blue-700">Client Management</span>
            <Link onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
              to="/clientRegistration"
              className="flex items-center px-4 py-2 mt-4  text-gray-800 hover:bg-blue-700 hover:text-white"
            >
              <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2 ml-2 transition-colors duration-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="gray"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
            <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 14l9-5-9-5-9 5 9 5z"
      />
        </svg>
              Registration
            </Link>
            <Link onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
              to="/lead"
              className="flex items-center px-4 py-2 mt-2  text-gray-800 hover:bg-blue-700 hover:text-white"
            >
              <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2 ml-2 transition-colors duration-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="gray"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
      />
        </svg>
              Lead
            </Link>
            <Link onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
              to="/active"
              className="flex items-center px-4 py-2 mt-2  text-gray-800 hover:bg-blue-700 hover:text-white"
            >
                <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-2 ml-2 transition-colors duration-300"
          fill="none"
          viewBox="0 0 24 24"
          stroke="gray"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
        </svg>
              Active
            </Link>
          </nav>
         
         </div>
        </div>
      
      {openNotification && <Notification onClose={handleOnClose}/>}
    </>
  );
};

export default SideBarMain;
