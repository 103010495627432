import Axios from 'axios';

const apiServices = {
  getBaseUrl(apiType) {
    if (apiType === "authentication") {
      return process.env.REACT_APP_REGISTRATION_BASE_URL;
    }
    console.log(process.env.REACT_APP_REGISTRATION_BASE_URL);
    // if more cases please add on.....
    return "";
  },
  async request(
    apiType,
    method,
    endPoint,
    headers,
    params = null,
    data = null
  ) {
    try {
      const baseUrl = this.getBaseUrl(apiType);
      const response = await Axios({
        method,
        url: `${baseUrl}${endPoint}`,
        headers,
        params,
        data,
      });
      return response.data;
    } catch (error) {
      console.error("error comming from apiservice", error);
      throw error;
    }
  },
  post(apiType, endPoint, headers, params, data) {
    return this.request(apiType, "POST", endPoint, headers, params, data);
  },
  get(apiType, endPoint, headers, params) {
    return this.request(apiType, "GET", endPoint, headers, params);
  },
  put(apiType, endPoint, headers, params, data) {
    return this.request(apiType, "PUT", endPoint, headers, params, data);
  },
  patch(apiType, endPoint, params) {
    return this.request(apiType, "PATCH", endPoint, params);
  },
};
export default apiServices;
