import React, { useState } from "react";
import ReviewDetails from "./ReviewDetails";
import BankDetails from "./BankDetails";
import EmployeeVerification from "./EmployeeVerification";
import PersonalDetail from "./PersonalDetail";
import background from "../../../assets/background.png";

const Registration = () => {
  const [currentStep, setCurrentStep] = useState(0);

  // Initialise all input value in form.....
  const [data, setData] = useState({
    imageUpload: "",
    name: "",
    lastname: "",
    password: "",
    confirmpassword: "",
    department: "",
    designation: "",
    official_email: "",
    alternate_email: "",
    permanent_address: "",
    current_address: "",
    contact_no: "",
    alternate_contact_no: "",
    adhar: "",
    PanCard: "",
    qualification: "",
    Specialization: "",
    College: "",
    Year: "",
    Marksheet: "",
    Relieving: "",
    Slip: "",
    BankName: "",
    AccountHolderName: "",
    AccountNo: "",
    ConfirmAcc: "",
    IFSCCode: "",
    InHandSalary: "",
    Incentive: "",
    CancelledCheque: "",
  });

  const makeRequest = () => {
    console.log("form submitted", data);
    // api post request at formdata
  };
  const handleNextStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep(currentStep + 1);
  };
  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep(currentStep - 1);
  };
  const steps = [
    <PersonalDetail next={handleNextStep} data={data} setData={setData} />,
    <EmployeeVerification
      prev={handlePrevStep}
      next={handleNextStep}
      data={data}
    />,
    <BankDetails prev={handlePrevStep} next={handleNextStep} data={data} />,
    <ReviewDetails prev={handlePrevStep} data={data} onSubmit={makeRequest} />,
  ];

  return (
    <div
      className="min-h-screen  w-full bg-cover"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="inline-block flex-wrap bg-white w-[800px] md:w-[700px] md:ml-10 lg:ml-20 xl:ml-80  max-h-full max-w-full justify-center align-center border-2 border-grey-700 content-align px-6 py-4 my-6 mx-auto rounded-lg ">
        {steps[currentStep]}
      </div>
    </div>
  );
};

export default Registration;
