import React, { useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";

const PersonalDetail = ({ next, data, setData }) => {
  const [selectImage, setSelectImage] = useState(null);

  // select image function
  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setSelectImage(base64String); // Display the selected image
        setData((prevData) => ({
          ...prevData,
          imageUpload: base64String, // Update formData with the base64 string
        }));
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  // validation form
  const steponevalidation = Yup.object({
    name: Yup.string().min(2).max(25).required("Please enter your name"),
    designation: Yup.string()
      .min(2)
      .max(25)
      .required("Please select Designation"),
    department: Yup.string().required("Please select Department"),
    lastname: Yup.string().min(2).max(25).required("Please enter your name"),
    official_email: Yup.string().email().required("Please enter your email"),
    alternate_email: Yup.string().email().required("Please enter your email"),
    password: Yup.string()
      .min(7, "Password must be atleast 7 characters long")
      .max(20, "Password cannot more than 20 charcter long ")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("please enter the password"),
    confirmpassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Password must match"),
    permanent_address: Yup.string()
      .min(2)
      .max(25)
      .required("Please enter your address"),
    current_address: Yup.string()
      .min(2)
      .max(25)
      .required("Please enter your address"),
    contact_no: Yup.string()
      .required("required")
      .min(10, "too short")
      .max(10, "too long"),
    alternate_contact_no: Yup.string()
      .required("required")
      .min(10, "too short")
      .max(10, "too long"),
  });

  // execute function to call next page
  const handleSubmit = (values) => {
    next(values);
  };

  const defaultImage = "";

  return (
    <>
      <Formik
        validationSchema={steponevalidation}
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="w-full md:w-[90%] my-3 mx-8">
            <div className="flex flex-wrap px-3 mb-6">
              <div className="w-full md:w-1/2 px-3 "></div>
            </div>
            <div className="flex items-center pb-4 space-x-4">
              {selectImage && (
                <div className="flex-shrink-0">
                  <img
                    className="h-28 w-28 object-cover rounded-full"
                    src={selectImage || defaultImage}
                    alt="Current profile"
                  />
                </div>
              )}
              <label className="block relative overflow-hidden">
                <span className="sr-only">Choose profile photo</span>
                <Field
                  type="file"
                  name="selectImage"
                  accept="image/*"
                  onChange={handleSelectImage}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                <span className="file-button bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-700">
                  Select Image
                </span>
              </label>
            </div>

            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="name"
                  className=" block  mx-2  uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  First Name
                </label>
                <Field
                  className=" appearance-none block  md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  autoComplete="off"
                  name="name"
                  id="name"
                  placeholder="First Name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className=" text-red-500 text-left text-xs mb-2 italic"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="name"
                  className="mx-2  block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Last Name
                </label>
                <Field
                  className="appearance-none block  md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="lastname"
                  autoComplete="off"
                  name="lastname"
                  id="lastname"
                  placeholder="Last Name"
                />

                <ErrorMessage
                  component="div"
                  name="lastname"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                <label
                  htmlFor="name"
                  className=" mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Department
                </label>
                <div className="relative">
                  <Field
                    name="department"
                    as="select"
                    id="department"
                    className=" md:w-[280px]  w-[350px] block appearance-none mb-2  bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-32 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option>Department</option>
                    <option>New Mexico</option>
                    <option>Missouri</option>
                    <option>Texas</option>
                  </Field>
                  <div className="pointer-events-none absolute inset-y-0 -right-2 flex items-center px-10 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>

                <ErrorMessage
                  component="div"
                  name="department"
                  className=" text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-6 -mx-3  md:mb-0">
                <label
                  htmlFor="name"
                  className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Designation
                </label>
                <Field
                  className="appearance-none block  md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="name"
                  autoComplete="off"
                  name="designation"
                  id="designation"
                  placeholder="Designation"
                />

                <ErrorMessage
                  component="div"
                  name="designation"
                  className="text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 ">
              <div className="w-full md:w-1/2 px-3 md:mb-0">
                <label
                  htmlFor="name"
                  className=" mx-2 block uppercase tracking-wide text-left text-gray-700 text-xs font-bold mb-2"
                >
                  password
                </label>
                <Field
                  className="md:w-[280px] w-[350px] none block  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="password"
                  autoComplete="off"
                  name="password"
                  id="password"
                  placeholder="Password"
                />

                <ErrorMessage
                  component="div"
                  name="password"
                  className=" text-red-500 text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="name"
                  className="mx-2 block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Confirm Password
                </label>
                <Field
                  className="md:w-[280px]  w-[350px] appearance-none block  bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4  mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="password"
                  autoComplete="off"
                  name="confirmpassword"
                  id="confirmpassword"
                  placeholder="Confirm Password"
                />

                <ErrorMessage
                  component="div"
                  name="confirmpassword"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 px-3 ">
              <div className="flex flex-wrap md:w-1/2 -mx-3  w-full px-3">
                <label
                  htmlFor="email"
                  className=" mx-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Official Email Id
                </label>
                <Field
                  className=" appearance-none block  md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded  py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="email"
                  autoComplete="off"
                  name="official_email"
                  id="official_email"
                  placeholder="Official Email"
                />

                <ErrorMessage
                  component="div"
                  name="official_email"
                  className=" text-red-600 text-xs italic mb-2"
                ></ErrorMessage>
              </div>
              <div className=" mx-3 md:w-1/2 w-full px-3">
                <label
                  htmlFor="email"
                  className="px-6 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Alternate Email Id
                </label>
                <Field
                  className="mx-3  appearance-none block md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="email"
                  autoComplete="off"
                  name="alternate_email"
                  id="alternate_email"
                  placeholder="Alternate Email"
                />

                <ErrorMessage
                  component="div"
                  name="alternate_email"
                  className=" float-left text-red-600 text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="w-[500px] md:w-1/2 px-3  md:mb-0">
                <label
                  htmlFor="name"
                  className="mx-2  text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Permanent Address
                </label>
                <Field
                  className=" appearance-none block  md:w-[280px] w-[350px]  bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="name"
                  autoComplete="off"
                  name="permanent_address"
                  id="permanent_address"
                  placeholder="Permanent Address"
                />

                <ErrorMessage
                  component="div"
                  name="permanent_address"
                  className="text-red-500 ml-20 text-left text-xs  mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3 ">
                <label
                  htmlFor="name"
                  className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Current Address
                </label>
                <Field
                  className="appearance-none block md:w-[280px] w-[350px]  bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  autoComplete="off"
                  name="current_address"
                  id="current_address"
                  placeholder="Current Address"
                />

                <ErrorMessage
                  component="div"
                  name="current_address"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-[500px]  md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  htmlFor="name"
                  className="mx-2 text-left  block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Contact No.
                </label>
                <Field
                  className="appearance-none block    md:w-[280px] w-[350px] bg-gray-200 text-gray-700 border border-red-500 rounded py-2 px-4 mb-2 leading-tight focus:outline-none focus:bg-white"
                  type="number"
                  autoComplete="off"
                  name="contact_no"
                  id="contact_no"
                  placeholder="Contact No."
                />
                <ErrorMessage
                  component="div"
                  name="contact_no"
                  className="text-red-500  text-left text-xs mb-2 italic"
                ></ErrorMessage>
              </div>
              <div className="w-full md:w-1/2 px-3 ">
                <label
                  htmlFor="name"
                  className="mx-2 text-left block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  Alternate Contact no.
                </label>
                <Field
                  className="appearance-none block md:w-[280px]  w-[350px] bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="number"
                  autoComplete="off"
                  name="alternate_contact_no"
                  id="alternate_contact_no"
                  placeholder="Alternate Contact No."
                />

                <ErrorMessage
                  component="div"
                  name="alternate_contact_no"
                  className="text-red-500 text-left text-xs italic mb-2"
                ></ErrorMessage>
              </div>
            </div>
            <div className="my-2 flex justify-between float-right">
              <button
                className="bg-gray-600 text-white hover:bg-gray-600 hover:text-gray-300 mx-3 px-4 py-2 -my-2 float-right rounded-xl"
                type="submit"
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalDetail;
