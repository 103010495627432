import React, { useState } from "react";
import profile from "../../../assets/profile.png";

const array = [
  {
    photo: profile,
    name: "Kamilia klkDugala",
    age: 20,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 32,
  },
  {
    photo: profile,
    name: "Kam;lkkmilia Dugala",
    age: 39,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 82,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 87,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 92,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 45,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 30,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 12,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 13,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 33,
  },
  {
    photo: profile,
    name: "Kamilia Dugala",
    age: 72,
  },
];
const TableLeaderBoard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = array.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(array.length / recordsPerPage);

  // for handling the pagination function

  function prevPage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      <div className="lg:ml-3 xl:w-[700px] lg:w-[520px] sm:w-[310px] divide-gray-200    border-2 border-grey-700 content-align   rounded-lg float-left flex flex-col ">
        <div className="border-b lg:py-3 sm:py-1 bg-gradient-to-r from-blue-300 to-green-300">
          <span className="lg:text-[15px] xl:text-[18px] md:text-[10px] font-medium text-white md:mr-10 lg:mr-20 sm:pl-4 lg:pl-8 py-4 text-left">
            Monthly LeaderBoard
          </span>
          <span className="lg:text-[15px] xl:text-[18px] sm:text-[10px] font-medium text-blue-800 xl:pl-[300px] lg:pl-[139px] sm:pl-[90px] py-4 text-right">
            February
          </span>
        </div>
        <div className="-mx-2">
          <div className="mx-3">
            <div className="relative overflow-x-auto shadow-md rounded-lg">
              <table className=" w-full pb-2 text-sm text-left rtl:text-right text-gray-500">
                <thead className="xl:text-[14px] lg:text-[12px] sm:text-[8px] text-gray-700">
                  <tr>
                    <th scope="col" className="xl:pl-12 pl-6 xl:pr-60 sm:pr-16  py-3">
                      Name
                    </th>
                    <th scope="col" className="pl-22 py-3">
                      Active Clients
                    </th>
                  </tr>
                </thead>
                <tbody>
          
                  {records.map((item, index) => (
                    <tr
                      key={index}
                      className="xl:text-[16px]  rounded-xl border mt-1  border-green-200  lg:text-[12px] sm:text-[10px] bg-white border-b cursor-pointer "
                    >
                      <th
                        scope="row"
                        className="rounded-l-2xl pb-1 mt-1  flex font-medium whitespace-nowrap"
                      >
                        <img
                          className="xl:ml-10 ml-4 lg:w-8 lg:h-8 mr-3 sm:w-6 sm:h-6 rounded-full"
                          src={item.photo}
                          alt="Jese"
                        />
                        <span className="mt-1">{item.name}</span>
                      </th>
                      <th className="xl:pl-8 pl-6 font-normal">{item.age}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="overflow-hidden">
              <div className="lg:w-3/5 w-full  flex  justify space-between border-t border-gray-200">
                <div className="flex flex-1 lg:mr-12 xl:mr-28 sm:mr-12 flex-start">
                  <button
                    onClick={prevPage}
                    className="flex items-center xl:pr-20 px-6 py-3 font-sans text-xs font-bold text-gray-900 uppercase transition-all rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20"
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                      ></path>
                    </svg>
                    Previous
                  </button>
                </div>
                <div className="flex xl:ml-80 lg:ml-60  sm:ml-20 flex-1 flex-end">
                  <button
                    className="flex items-center py-3 font-sans text-xs font-bold text-gray-900 uppercase rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20"
                    type="button"
                    onClick={nextPage}
                  >
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableLeaderBoard;
