import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../components/auth/login";
import ForgetPassword from "../components/auth/forgetPassword";
import Registration from "../components/auth/employeeRegister/Registration";
import ClientRegistration from "../components/page/clientRegistration/clientRegistration";
import Lead from "../components/page/lead/lead";
import LeadProfile from "../components/page/lead/leadProfile";
import DashBoard from "../components/page/dashBoard/dashBoard";
import Active from "../components/page/active/active";
import PaymentModal from "../components/page/lead/paymentOption";

const AcesssRoute = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/dashBoard" element={<DashBoard />} />
          <Route path="/clientRegistration" element={<ClientRegistration />} />
          <Route path="/lead" element={<Lead />} />
          <Route path="/leadProfile" element={<LeadProfile />} />
          <Route path="/active" element={<Active />} />
          <Route path="/paymentMode" element={<PaymentModal/>}/>
        </Routes>
      </Router>
    </>
  );
};
export default AcesssRoute;
