import React, { useState } from "react";
import SideBarMain from "../../../widgets/sideBarMain";
import EditLogo from "../../../assets/editLogo.png";

const Active = () => {
  const [open, setOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);

  const handleDayClick = (index) => {
    const isDaySelected = selectedDays.includes(index);
    if (isDaySelected) {
      setSelectedDays(selectedDays.filter((day) => day !== index));
    } else {
      setSelectedDays([...selectedDays, index]);
    }
  };
  return (
    <>
      <div className="flex h-screen bg-white">
        <div className="hidden md:block">
          <SideBarMain />
        </div>

        {open && <SideBarMain />}
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div className="md:hidden flex items-center justify-between h-16 bg-white border-b border-gray-200">
            <div className="flex items-center py-5 px-4">
              <div
                onClick={() => {
                  setOpen(!open);
                }}
                className="text-gray-500 focus:outline-none focus:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-wrap ml-12 py-3 text-white px-6 font-bold mt-10 w-[85%] border-b bg-gradient-to-r from-blue-300 to-green-300">
              <div className="inline xl:pr-60">Active Lead Client Profile</div>
              <div className="text-right pl-20 lg:pl-60 ml-20  md:mb-0">
                <button className="px-5 py-[2px] bg-white text-black rounded-md">
                  <img alt="img" className="image inline pb-1" src={EditLogo} />{" "}
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div className="p-2 mt-4 lg:text-[14px] md:text-[10px]">
            <div className="flex flex-wrap">
              <div className="w-[50%] ml-12">
                <div className="flex flex-wrap ">
                  <div className=" text-gray-400 lg:w-2/5 md:w-2/6 md:mb-0">
                    Name
                  </div>
                  <div className="mb-1 md:w-[120px] lg:w-[240px]">
                    Dr. A. Shukla
                  </div>
                  <div className=" text-gray-400 lg:w-2/5 md:w-2/6 md:mb-0">
                    Specialization
                  </div>
                  <div className=" w-full md:w-[120px] lg:w-[240px]">
                    Pediatrics
                  </div>
                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/6 md:mb-0">
                    Compounder Name
                  </div>
                  <div className=" mb-1 w-full md:w-[120px] lg:w-[240px] ">
                    Raju
                  </div>
                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/6 md:mb-0">
                    Email Id
                  </div>
                  <div className="w-full md:w-[120px] lg:w-[240px] ">
                    a.shukla10@gmail.com
                  </div>
                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/6 md:mb-0">
                    Address
                  </div>
                  <div className="mb-1 w-full md:w-[120px] lg:w-[240px] ">
                    105/178 Gandhi Maidan, Patliputra Colony
                  </div>
                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/6 md:mb-0">
                    City
                  </div>
                  <div className="mb-1w-full md:w-[120px] lg:w-[240px] ">
                    Patna
                  </div>

                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/6 md:mb-0">
                    State
                  </div>
                  <div className="mb-1 w-full md:w-[120px] lg:w-[240px] ">
                    Bihar
                  </div>
                </div>
              </div>
              <div className="w-[49%] -mx-8">
                <div className="flex flex-wrap ">
                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/5 md:mb-0">
                    Compounder Number
                  </div>
                  <div className="w-full md:w-[80px] lg:w-[200px] ">
                    9870623822
                  </div>
                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/5 md:mb-0">
                    Doctor Number
                  </div>
                  <div className="w-full md:w-[80px] lg:w-[200px] ">
                    9870623822
                  </div>
                  <div className="w-full text-gray-400 lg:w-2/5 md:w-2/5 md:mb-0">
                    Clinic Timing
                  </div>
                  <div className="w-full md:w-[80px] lg:w-[200px]">
                    12:00 - 17:00
                  </div>
                </div>
                <div className="w-full text-gray-400 lg:w-2/5 md:w-2/5 md:mb-0">
                  Clinic Days
                </div>
                <div className="flex  p-2 rounded-md">
                  {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
                    <div
                      key={index}
                      className={`ml-1 flex items-center justify-center md:w-6 md:h-6 lg:w-8 lg:h-8 cursor-pointer ${
                        selectedDays.includes(index)
                          ? "border-2 bg-sky-900 text-white"
                          : "border border-1 border-gray-400"
                      }`}
                      onClick={() => handleDayClick(index)}
                    >
                      {day}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <button
              className="ml-12 mt-2 text-white bg-teal-400 rounded-md lg:px-10 md:px-6 py-1"
              type="button"
            >
              Upload Contract{" "}
            </button>
          </div>

          <div>
            <hr className="mt-4 h-px  ml-14 mx-20 bg-blue-400 border-0 dark:bg-gray-700"></hr>
            <div className="mt-4 flex flex-row lg:text-[14px] md:text-[10px]">
              <div className="ml-16 w-2/5">
                <div className="flex flex-wrap ">
                  <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                    Customer Support
                  </div>
                  <div className="mb-1 w-full md:w-1/2">A. Gupta</div>
                  <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                    Date of Contract
                  </div>
                  <div className="mb-1 w-full md:w-1/2">10/03/2024</div>
                </div>
              </div>
              <div className="-ml-6 w-2/5">
                <div className="flex flex-wrap ">
                  <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                    Installation Date
                  </div>
                  <div className="mb-1 w-full md:w-1/2">10/03/2024</div>
                  <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                    Monthly Subscription
                  </div>
                  <div className="mb-1 w-full md:w-1/2">10/03/2024</div>
                </div>
              </div>
            </div>
          </div>

          <hr className="ml-14 h-px mx-20 my-4 bg-blue-400 border-0 dark:bg-gray-700"></hr>
          <div className="flex flex-wrap">
            <div className="w-3/5 ml-14">
              <div className="text-lg mb-4 md:font-semibold lg:font-bold">
                Visit Analysis
              </div>

              <div className="w-full">
                <div className="relative w-full">
                  <textarea
                    className="peer h-full md:h-[100px] lg:min-h-[120px] w-full shadow-lg resize-none rounded-[7px] border border-1 border-blue-gray-600 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=" "
                  ></textarea>
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Notes
                  </label>
                </div>
              </div>
            </div>

            <div className="w-1/5 ml-6 lg:text-[15px] md:text-[10px]">
              <button
                className=" text-white bg-blue-800 rounded-md px-4 py-1"
                type="button"
              >
                Schedule Visit
              </button>
              <div className="mt-2">24 Feb,2024</div>
              <div>14:47</div>
              <button
                className="text-white bg-blue-800 rounded-md px-4 py-1 mt-2 mr-10"
                type="button"
              >
                Visit Complete
              </button>
              <button className="mt-2 border border-1 border-gray-400 px-5 py-[2px] bg-white text-black rounded-md">
                <img alt="img" className="image inline pb-1" src={EditLogo} />{" "}
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Active;
