import React from "react";

import { useNavigate } from "react-router-dom";
const PaymentModal = ({ onClose }) => {
  const naviagte = useNavigate();

  const handleOnPayment = (e) => {
    if (e.target.id === "container") {
      onClose();
    }
  };
  const handlePaymentMode = () => {
    naviagte("/paymentMode");
  };

  return (
    <>
      <div
        onClick={handleOnPayment}
        id="container"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
      >
        <div className="bg-white  w-[620px] h-[325px] rounded-xl">
          <div className="px-20 min-h-full">
            <div className=" bg-white ">
              <div className="flex px-6 xl:pt-24 rounded-t ">
                <h3 className="text-3xl font-semibold text-gray-900">
                  Receive Payment for Rs.3000
                </h3>
              </div>
              <div className="p-4 xl:pt-1  ">
                <div>
                  <div className="grid grid-cols-2 mt-4">
                    <div className="ml-6 bg-gray-300 border font-bold  text-black border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-800  w-[185px] p-2.5 pl-4 placeholder:font-semibold ">
                      Upload Contract
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="inline ml-2 pb-1"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="17 8 12 3 7 8"></polyline>
                        <line x1="12" y1="3" x2="12" y2="15"></line>
                      </svg>
                    </div>
                    <div className=" bg-blue-800 border ml-6  text-white border-gray-300  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-800  w-[125px] p-2.5 pl-4 placeholder:font-semibold ">
                      <button type="button" onClick={handlePaymentMode}>
                        Activate Client
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentModal;
