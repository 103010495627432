import React from "react";

const ReviewDetails = ({ prev, data, onSubmit }) => {
  return (
    <div>
      <div className="flex flex-wrap px-3 mb-6">
        <div className="w-full md:w-1/2 px-3 "></div>
      </div>
      <div className="p-2 mt-4">
        <div className="flex flex-wrap">
          <div className="w-[50%] ml-8">
            <div className="flex flex-wrap ">
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">Name</div>
              <div className="w-full  mb-1  md:w-1/2">
                {data.name} {data.lastname}
              </div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Department
              </div>
              <div className=" w-full md:w-1/2 mb-1">{data.department}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Designation
              </div>
              <div className=" mb-1 w-full md:w-1/2 ">{data.designation}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Password
              </div>
              <div className="w-full md:w-1/2 ">{data.password}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Official Email
              </div>
              <div className="mb-1 w-full md:w-1/2 ">{data.official_email}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Alternate Email id
              </div>
              <div className="mb-1w-full md:w-1/2 ">{data.alternate_email}</div>

              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Permanent Address
              </div>
              <div className="mb-1 w-full md:w-1/2 ">
                {data.permanent_address}
              </div>

              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Current Address
              </div>
              <div className="mb-1 w-full md:w-1/2 ">
                {data.current_address}
              </div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Contact no.
              </div>
              <div className="mb-1 w-full md:w-1/2 ">{data.contact_no}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Alternate Contact No.
              </div>
              <div className="mb-1 w-full md:w-1/2 ">
                {data.alternate_contact_no}
              </div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Highest Qualification
              </div>
              <div className="mb-1 w-full md:w-1/2 ">{data.qualification}</div>
            </div>
          </div>
          <div className="w-[50%] -mx-8">
            <div className="flex flex-wrap ">
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Specialization
              </div>
              <div className="mb-1 w-full md:w-1/2 ">{data.Specialization}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                College
              </div>
              <div className="mb-1 w-full md:w-1/2 ">{data.College}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">Year</div>
              <div className="mb-1 w-full md:w-1/2 ">{data.Year}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Bank Name
              </div>
              <div className="w-full mb-1 md:w-1/2 ">{data.BankName}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Acc. Holder Name
              </div>
              <div className="w-full md:w-1/2 ">{data.AccountHolderName}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Account No.
              </div>
              <div className="w-full md:w-1/2 ">{data.AccountNo}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                IFSC Code
              </div>
              <div className="w-full md:w-1/2 ">{data.IFSCCode}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                In Hand Salary
              </div>
              <div className="w-full md:w-1/2 ">{data.InHandSalary}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Branch Name
              </div>
              <div className="w-full md:w-1/2 ">{data.BranchName}</div>
              <div className="w-full text-gray-400 md:w-1/2 md:mb-0">
                Incentive
              </div>
              <div className="w-full md:w-1/2 ">{data.Incentive}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-2 flex justify-between items-center">
        <button
          type="button"
          className="bg-gray-600 text-white hover:bg-gray-600 hover:text-gray-300 px-4 py-2 rounded-xl"
          onClick={prev}
        >
          Prev
        </button>
        <button
          type="submit"
          className="bg-gray-600 text-white hover:bg-gray-600 hover:text-gray-300 mx-3 px-4 py-2 -my-2 float-right rounded-xl"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ReviewDetails;
