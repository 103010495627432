import React from "react";
import AcesssRoute from "./route/route";
const App = () => {
  return (
    <>
      <AcesssRoute />
      
    </>
  );
};

export default App;
