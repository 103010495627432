import React, { useState } from "react";
import reshitaLogo from "../../assets/image 11.png";
import background from "../../assets/background.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import apiServices from "../../service/apiService";
import Spinner from "../page/loading/Spinner";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().required("please enter email"),
      password: Yup.string().required("please enter the password"),
    }),
    onSubmit: (values) => {
      loginFunction(values);
    },
  });
  const loginFunction = async (values) => {
    try {
      setLoading(true);
      const response = await apiServices.post(
        "authentication",
        "/login",
        {
          "Content-Type": "application/json",
        },
        null,
        {
          email: values.email,
          password: values.password,
        }
      );
      if (response) {
        //response.statuscode===200
        navigate("/dashBoard");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="relative">
        <div className="h-screen  bg-white text-gray-900 flex flex-col items-center justify-between">
          <div className="max-w-screen-xl m-0 sm:m-10 shadow sm:rounded-lg flex justify-center lg:flex-1 xl:flex">
            <div className="lg:flex-1 xl:flex bg-white text-center flex flex-col lg:mb-20 lg:justify-center items-center">
              {/* Doc-Aid Logo */}
              <div className="w-full flex justify-center items-center">
                <div className="w-full  mt-20">
                  <img
                    alt="img"
                    className="w-full  lg:w-[80%] xl:w-[90%] justify-center"
                    src={reshitaLogo}
                  />
                </div>
              </div>
              {/* for mobile screen User Interface */}
              <div
                className="absolute bottom-0  lg:hidden w-full "
                style={{
                  backgroundImage: `url(${background})`,
                  borderTopLeftRadius: "40px",
                  borderTopRightRadius: "40px",
                }}
              >
                <div className="lg:w-1/2 xl:w-1/2 p-6 sm:p-16 flex justify-center items-center">
                  {/* input box */}
                  <div
                    style={{
                      backgroundColor: "rgb(188, 190, 176)",
                      backgroundImage: `url(${background})`,
                      backgroundSize: "cover",
                    }}
                    className="bg-blend-overlay box-border inline-block flex-wrap w-[480px] max-h-full max-w-full justify-center align-center  content-align mb-20 px-6 mt-20 py-4 rounded-lg "
                  >
                    <div className="w-full mt-4rem">
                      <div className="my-1 border-b text-center">
                        <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"></div>
                      </div>

                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                      >
                        <div className="mx-auto">
                          <input
                            className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                            type="text"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder="Email"
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <p style={{ color: "red" }}>
                              {formik.errors.email}
                            </p>
                          ) : null}
                          <input
                            className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                            type="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            placeholder="Password"
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <p style={{ color: "red" }}>
                              {formik.errors.password}
                            </p>
                          ) : null}
                          <div className="text-right font-sans">
                            <label className="block text-gray-500 font-bold my-4" />

                            <label className="block text-gray-500 font-semibold my-4">
                              <Link
                                to="/forgetPassword"
                                className=" cursor-pointer te tracking-tighter text-black border-gray-200 hover:border-gray-400"
                              >
                                Forgot Password
                              </Link>
                            </label>
                          </div>
                          <button
                            type="Submit"
                            className="mb-10 mt-2 tracking-wide font-semibol text-white w-full py-4 rounded-lg  transition-all bg-blue-800 duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                          >
                            <span className="ml- ">Log In</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="hidden lg:block lg:w-1/2 xl:w-10/18 p-6 sm:p-12"
              style={{
                backgroundImage: `url(${background})`,
                backgroundSize: "cover",
              }}
            >
              {/* input box */}
              <div
                style={{
                  backgroundColor: "rgb(188, 190, 176)",
                  backgroundImage: `url(${background})`,
                  backgroundSize: "cover",
                }}
                className="bg-blend-overlay box-border inline-block flex-wrap w-[480px] lg:w-[480px] max-h-full max-w-full justify-center align-center  content-align  px-6 mt-8 xl:mt-10 rounded-lg "
              >
                <div className="mt-12  flex flex-col items-center">
                  <div className="w-full flex-1 mt-8">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      <div className="mx-auto">
                        <input
                          className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                          type="text"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          placeholder="Email"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p style={{ color: "red" }}>{formik.errors.email}</p>
                        ) : null}
                        <input
                          className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                          type="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          placeholder="Password"
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <p style={{ color: "red" }}>
                            {formik.errors.password}
                          </p>
                        ) : null}
                        <div className="text-right font-sans">
                          <label className="block text-gray-500 font-bold my-4" />

                          <label className="block text-gray-500 font-semibold my-4">
                            <Link
                              to="/forgetPassword"
                              className=" cursor-pointer te tracking-tighter text-black border-gray-200 hover:border-gray-400"
                            >
                              Forgot Password
                            </Link>
                          </label>
                        </div>
                        <button
                          type="Submit"
                          className="mb-10 mt-2 tracking-wide font-semibol text-white w-full py-4 rounded-lg  transition-all bg-blue-800 duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                        >
                          <span className="ml- ">Log In</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};

export default Login;
