import React, { useState } from "react";
import SideBarMain from "../../../widgets/sideBarMain";
import { useNavigate } from "react-router-dom";

const array = [
  {
    name: "Dr. Shmnnmukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, sjjljhashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patnkklklla",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
  {
    name: "Dr. Shukla",
    address: "128, shashi arcade, Gandhi Maidan",
    city: "Patna",
    days: "SMTWTFS",
    time: "13.00- 19.00",
  },
];
const Lead = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = array.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(array.length / recordsPerPage);

  // for handling the pagination function

  function prevPage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  }
  return (
    <>
      <div className="flex h-screen bg-white">
        <div className="hidden md:block">
          <SideBarMain />
        </div>

        {open && <SideBarMain />}
        <div className="flex flex-col flex-1 overflow-y-auto">
          <div className="md:hidden flex items-center justify-between h-16 bg-white border-b border-gray-200">
            <div className="flex items-center py-5 px-4">
              <div
                onClick={() => {
                  setOpen(!open);
                }}
                className="text-gray-500 focus:outline-none focus:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="lg:mx-12 sm:mx-3 mt-6 w-[99%] sm:w-[93%] divide-gray-200 content-align rounded-lg float-left flex flex-col ">
              <div className="mt-4 py-3 border-b bg-gradient-to-r from-blue-300 to-green-300">
                <span className="text-[15px]  text-white  font-medium  mr-20 pl-8 py-4 text-left">
                  Client Leads
                </span>
              </div>
              <div className="sm:-mx-6 mt-6 ">
                <div className=" sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead className="lg:text-[12px] sm:text-[10px] xl:text-[14px] text-gray-700 uppercase ">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Address
                            </th>
                            <th scope="col" className="px-6 py-3">
                              City
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Days
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Time
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => navigate("/leadProfile")}
                              className=" xl:text-[14px] lg:text-[12px] sm:text-[10px] bg-white border-b cursor-pointer"
                            >
                              <th
                                scope="row"
                                className="px-6 py-2 font-medium whitespace-nowrap "
                              >
                                {item.name}
                              </th>
                              <td className="px-6 py-2">{item.address}</td>
                              <td className="px-6 py-2">{item.city}</td>
                              <td className="px-6 py-2">{item.days}</td>
                              <td className="px-2 py-2">{item.time}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="w-full  flex  justify space-between border-t border-gray-200">
                      <div className="flex flex-1 mr-60 flex-start">
                        <button
                          onClick={prevPage}
                          className="flex items-center px-4 py-3  font-sans text-xs font-bold text-gray-900 uppercase transition-all rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20"
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            aria-hidden="true"
                            className="w-4 h-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                            ></path>
                          </svg>
                          Previous
                        </button>
                      </div>
                      <div className="flex lg:ml-52 ml-36 xl:ml-72 flex-1 flex-end">
                        <button
                          className="flex py-3 font-sans text-xs font-bold text-gray-900 uppercase rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20"
                          type="button"
                          onClick={nextPage}
                        >
                          Next
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            aria-hidden="true"
                            className="w-4 h-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lead;
